import jquery3 from "jquery";

window.addEventListener('load', () => {
  var element = document.getElementById("whitelistWebsiteModal");
  if (element !== null) {
    var whitelistWebsiteModal = document.getElementById('whitelistWebsiteModal')

    whitelistWebsiteModal.addEventListener('show.bs.modal', function () {
      var button = jquery3(event.relatedTarget);// Button that triggered the modal
      var whiteListedWebsiteIp = button.data('whitelisted-ip') // Extract info from data-* attributes
      var whitelistSubmitUrl = button.data('submit-url');

      var modal = jquery3(this)
      modal.find('#whiteListedWebsiteIp').text(whiteListedWebsiteIp);
      modal.find('form')[0].action = whitelistSubmitUrl;
    })

    const deleteConfirmInput = document.querySelector('#deleteConfirm');
    const deleteConfirmButton = document.querySelector('#deleteConfirmButton');

    deleteConfirmInput.addEventListener('input', (event) => {
      if (event.target.value === "DELETE") {
        deleteConfirmButton.disabled = false;
      } else {
        deleteConfirmButton.disabled = true;
      }
    });
  }
});
